// Charge la base commune
import './common.js'

// Scripts librairies
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// rafraîchissement
function r () {
  Waypoint.refreshAll()
}

// OwlCarousel
async function getOwlCarousel () {
  import(/* webpackChunkName: "owl-carousel" */ './js/components/owl-carousel.js').then(() => {
    r()
  })
}

// Contact Form
async function getContactForm ($f) {
  import(/* webpackChunkName: "contact-form" */ './js/components/contact-form.js').then(() => {
    $f.load(false)
    r()
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion des carousel
  const $d = $('.owl-carousel.c')
  if ($d.length) {
    $d.waypoint(function () {
      getOwlCarousel()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du formulaire de contact
  const $f = $('.contact-form')
  if ($f.length) {
    $f.waypoint(function () {
      $f.load()
      getContactForm($f)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion cta
  const $cta = $('.cta')
  if ($cta.length) {
    $cta.waypoint(function () {
      import(/* webpackChunkName: "cta" */ './scss/components/cta.scss').then(() => {
        Waypoint.refreshAll()
      })
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // refresh waypoints au chargement d'une image
  $('.lazyload').on('lazyloaded', () => {
    r()
  })
})
